import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import SearchInput from "../components/SearchInput"

export default ({ data }) => {
  return (
    <Layout>
      <HeadMeta pageTitle="Page not found" />
      <PageHeader>Page not found</PageHeader>
      <Breadcrumb
        route={[
          { name: "Scanned card catalogue", href: "/" },
          { name: "Page not found", href: "/404/" },
        ]}
      />
      <p>
        The page you were looking for could not be found.
        <br />
        Please try searching our site, or browsing our <Link to="/">cards</Link>
        .
      </p>
      <SearchInput
        onSubmit={(query, lang) => {
          navigate(`/search?search=${query}&lang=${lang}`, {})
        }}
      />
    </Layout>
  )
}
